import React from "react"
import HomeLayout from "../layouts/HomeLayout"
import { graphql } from "gatsby"
import SEO from "../components/Seo"
import SectionTitle from "../components/SectionTitle"
const KaplicaPage = ({data}) => (
  <>
    <SEO title="Dom pogrzebowy ABRAM - Kaplica, Chłodnia, Kosmetykia pośmiertna, Różaniec za zmarłych" />
    <HomeLayout data={data}>
        <div id="kaplica" className="kaplica-page">
            <div className="container">
                <div className="content page-content">
                    <SectionTitle title="Dom pogrzebowy" bottom={false} />
                    <p>
                        Podstrona w budowie - zapraszamy ponownie niebawem.
                    </p>
                </div>
            </div>
        </div>
    </HomeLayout>
  </>
)
export const pageQuery = graphql`
    query {
        logo: file(relativePath: { eq: "logo_poziome.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
    }
`

export default KaplicaPage
